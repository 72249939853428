import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";

import {
  InputField,
  MultiSelect,
  MultipleSelectChip,
  PasswordFiled,
  SelectBox,
} from "../../../../component";
import { handleLoader, setToast } from "../../../../store/reducer";
import errorsSetter from "../../../../helpers/error-setter";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import Utils from "../../../../utils/utils";

const initialState = {
  topup_terminal: false,
  topup_station_user: false,
  vendor: false,
  open_id: "",
  isVendorNeed: [],
  isAdmin: [],
  approval_code_for_topup_refund: null,
};

const CreateUser = () => {
  const params = useParams();
  const { id } = params;
  const userID = params?.userID;
  usePageTitle(userID ? "Update User" : "Create User");
  const [formData, setFormData] = useState(initialState);
  const [formErrors, setFormErrors] = useState({});
  const [isDisable, setIsDisable] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [loadingPermissions, setLoadingPermissions] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [vendorsAndRoles, setVendorsAndRoles] = useState({
    vendorsList: [],
    rolesList: [],
    isVendorsLoading: false,
    hasVendorsError: false,
    isRolesLoading: false,
    hasRolesError: false,
  });

  const [selectedVendors, setSelectedVendors] = useState("");
  const [selectedRole, setSelectedRole] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const _path = `/event/${id}/management/user-management`;
  // const _path = `-1`;

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    async function init() {
      if (userID) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            `organizer/devices/${userID}?event_id=${id}`,
          );
          if (data?.vendor_devices) {
            setSelectedVendors(data?.vendor_devices?.vendor?.vendor_id);

            setSelectedRole(
              data?.vendor_devices?.vendor?.device_roles[0]?.device_role
                ?.device_role_id,
            );
          }
          setFormData((prev) => ({
            ...prev,
            name: data?.name,
            email: data?.email,
            open_id: data?.open_id,
            status: data?.status,
            vendor: data?.vendor_devices ? true : false,
            approval_code_for_topup_refund: data.approval_code_for_topup_refund,
          }));
          const filterData = data?.permissions?.filter((each) => {
            const title = each.device_permissions.title.toLowerCase();
            if (title === "vendor user" || title === "vendor manager") {
              return each;
            }
            return false;
          });

          const checkIsAdmin = data?.permissions?.filter((each) => {
            const title = each.device_permissions.title.toLowerCase();
            if (title === "settings admin" || title === "super admin") {
              return each;
            }
            return false;
          });

          if (checkIsAdmin.length) {
            setFormData((previous) => ({
              ...previous,
              isAdmin: checkIsAdmin.map((x) => x?.device_permission_id),
            }));
          }

          if (data?.permissions?.length) {
            if (!!filterData.length) {
              setFormData((previous) => ({
                ...previous,
                isVendorNeed: filterData.map((x) => x?.device_permission_id),
              }));
            }
            setSelectedPermissions(
              data?.permissions.map((x) => x?.device_permission_id),
            );
          }
        } catch (error) {
          console.log("🚀 ~ file: create-user.js:174 ~ init ~ error:", error);
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      }
    }
    init();
  }, [userID]);

  useEffect(() => {
    async function gettingPermissions() {
      try {
        setLoadingPermissions(true);
        let { data } = await ApiManager("get", "organizer/device-permissions");
        setPermissions(data);
      } catch (error) {
        showMessage("error", error?.response?.data?.error?.message);
      } finally {
        setLoadingPermissions(false);
      }
    }
    if (permissions.length === 0) {
      gettingPermissions();
    }
  }, []);

  useEffect(() => {
    async function gettingVendors() {
      try {
        setVendorsAndRoles((prev) => ({ ...prev, isVendorsLoading: true }));
        let { data } = await ApiManager(
          "get",
          `organizer/vendors?event_id=${id}&per_page=50`,
        );
        let _Obj =
          data?.vendors?.data.length > 0
            ? data?.vendors?.data.map((item) => ({
              label: item?.name,
              value: item?.vendor_id,
            }))
            : [];
        setVendorsAndRoles((prev) => ({
          ...prev,
          vendorsList: _Obj,
          isVendorsLoading: false,
          hasVendorsError: "",
        }));
      } catch (error) {
        console.log(
          "🚀 ~ file: create-user.js:129 ~ gettingVendors ~ error:",
          error,
        );
        setVendorsAndRoles((prev) => ({
          ...prev,
          vendorsList: [],
          isVendorsLoading: false,
          hasVendorsError: "Error getting records of vendors.",
        }));
      }
    }
    async function gettingRoles() {
      try {
        setVendorsAndRoles((prev) => ({ ...prev, isRolesLoading: true }));
        let { data } = await ApiManager("get", "organizer/device-roles");
        let _Obj =
          data?.length > 0
            ? data?.map((item) => ({
              label: item?.title,
              value: item?.device_role_id,
            }))
            : [];
        setVendorsAndRoles((prev) => ({
          ...prev,
          rolesList: _Obj,
          isRolesLoading: false,
          hasRolesError: "",
        }));
      } catch (error) {
        setVendorsAndRoles((prev) => ({
          ...prev,
          rolesList: [],
          isRolesLoading: false,
          hasRolesError: "Error getting roles for vendors.",
        }));
      }
    }

    if (formData?.vendor && vendorsAndRoles?.vendorsList?.length === 0) {
      gettingVendors();
    }
    if (formData?.vendor && vendorsAndRoles?.rolesList?.length === 0) {
      gettingRoles();
    }
  }, [formData?.vendor]);

  // useEffect(() => {
  //   console.log("🚀 ~ file: create-user.js:167 ~ useEffect ~ vendorsAndRoles:", vendorsAndRoles);

  //   console.log(formData);
  // }, [formData, vendorsAndRoles]);

  const validator = () => {
    let flag = false;
    let error = {};
    if (formData.pin && !Utils.isNumber(formData.pin)) {
      flag = true;
      error = { ...error, pin: "Pin must be number" };
    } else if (formData.pin && formData.pin.length > 6) {
      flag = true;
      error = { ...error, pin: "Pin must be only 6 number long" };
    } else if (formData.pin && formData.pin.length < 6) {
      flag = true;
      error = { ...error, pin: "Pin must be 6 number long" };
    } else if (
      formData?.isAdmin?.length &&
      !formData.approval_code_for_topup_refund
    ) {
      flag = true;
      error = {
        ...error,
        approval_code_for_topup_refund: "Enter approval code",
      };
    } else if (
      formData?.isAdmin?.length &&
      formData.approval_code_for_topup_refund?.length < 4
    ) {
      flag = true;
      error = {
        ...error,
        approval_code_for_topup_refund: "Must Enter 4 digit",
      };
    }
    setFormErrors(error);
    return flag;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let str = value;
    if (name === "open_id") {
      str = Utils.handleBandID(value, formData[name]);
    }
    setFormData((prev) => ({ ...prev, [name]: str }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedPermissions?.length === 0 && !formData.vendor) {
      return showMessage("warning", "Please select at least one permission.");
    }
    if (validator()) return;
    try {
      dispatch(handleLoader(true));
      setFormErrors({});
      let _url = userID ? `organizer/devices/${userID}` : "organizer/devices";
      let _fd = { ...formData, event: id };
      if (userID) {
        _fd._method = "patch";
        delete _fd.email;
        delete _fd.vendor_devices;
        delete _fd.open_id;
      }
      if (formData?.vendor) {
        _fd = { ..._fd, vendors: [selectedVendors], role: selectedRole };
      }
      if (selectedPermissions.length) {
        _fd.permissions = selectedPermissions;
      }

      await ApiManager("post", _url, _fd);
      if (userID) {
        showMessage("success", "User updated successfully.");
      } else {
        showMessage("success", "User created successfully.");
      }
      navigate(_path);
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const renderFormInputs = () => {
    return (
      <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
        <Grid item sm={4} xs={12}>
          <Box>
            <InputField
              label="Name"
              name="name"
              required
              error={formErrors?.name}
              value={formData?.name}
              onChange={handleInputChange}
            />
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <PasswordFiled
            label="Pin Code"
            name="pin"
            required={!userID}
            error={formErrors?.pin}
            value={formData?.pin}
            onChange={handleInputChange}
            helperText="Format [0-9],should be 6 digits long."
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <InputField
            label="Tag Open ID For (Fast Login)"
            name="open_id"
            required={!userID}
            disabled={Boolean(!!userID)}
            error={formErrors?.open_id}
            value={formData?.open_id}
            onChange={handleInputChange}
            helperText="Format [XX:XX:XX:XX]"
            onBlur={() => {
              setFormData((prevValue) => ({
                ...prevValue,
                open_id: prevValue?.open_id.trim().replace(/:$/, ""),
              }));
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Box>
            <SelectBox
              items={[
                { label: "Active", value: "active" },
                { label: "Disable", value: "inactive" },
              ]}
              label="Status"
              fullWidth
              name="status"
              required
              error={formErrors?.status}
              value={formData?.status}
              onChange={handleInputChange}
            />
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Box>
            <InputField
              label="Email"
              name="email"
              required={!userID}
              disabled={Boolean(!!userID)}
              error={formErrors?.email}
              value={formData?.email}
              onChange={handleInputChange}
            />
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Box>
            <PasswordFiled
              label="Password"
              name="password"
              required={!userID}
              error={formErrors?.password}
              value={formData?.password}
              onChange={handleInputChange}
            />
          </Box>
        </Grid>
      </Grid>
    );
  };

  const renderCheckBoxes = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" flexWrap="wrap">
            {permissions.map((item, _) => {
              return (
                <MyCheckBox
                  key={item?.device_permission_id}
                  item={item}
                  // callVendor={(e) => {
                  //   setFormData((prev) => ({ ...prev, vendor: e }));
                  // }}
                  values={selectedPermissions}
                  callBack={setSelectedPermissions}
                  isDisable={isDisable}
                  formData={formData}
                  setFormData={setFormData}
                />
              );
            })}
          </Stack>
        </Grid>
      </Grid>
    );
  };

  const conditionalInputBox = () => {
    return (
      <>
        <Box
          sx={{
            borderTop: 1,
            borderColor: "divider",
            my: 2,
            py: 2,
          }}
        >
          {!!formData?.isVendorNeed?.length && (
            <Typography variant="h6">Device User Assignment</Typography>
          )}

          {!!formData?.isVendorNeed?.length && (
            <>
              <Grid container spacing={3} my={1}>
                <Grid item sm={5} xs={12}>
                  {vendorsAndRoles?.isVendorsLoading ? (
                    "...loading Vendors List"
                  ) : vendorsAndRoles?.vendorsList?.length ? (
                    <SelectBox
                      size="small"
                      items={vendorsAndRoles?.vendorsList}
                      value={selectedVendors}
                      onChange={(e) => setSelectedVendors(e.target.value)}
                      fullWidth
                      required={formData?.isVendorNeed?.length}
                      label="Vendors"
                    />
                  ) : !!vendorsAndRoles?.hasVendorsError ? (
                    vendorsAndRoles?.hasVendorsError
                  ) : (
                    "Vendors not found."
                  )}
                </Grid>
                <Grid item sm={4} xs={12}>
                  {vendorsAndRoles?.isRolesLoading ? (
                    "...loading Roles for Vendor"
                  ) : vendorsAndRoles?.rolesList.length ? (
                    <SelectBox
                      size="small"
                      items={vendorsAndRoles?.rolesList}
                      fullWidth
                      required={formData?.isVendorNeed.length}
                      value={selectedRole}
                      onChange={(e) => setSelectedRole(e.target.value)}
                      label="Set Vendor User Role"
                    />
                  ) : !!vendorsAndRoles?.hasRolesError ? (
                    vendorsAndRoles?.hasRolesError
                  ) : (
                    "Roles for vendor not found."
                  )}
                </Grid>
              </Grid>
            </>
          )}
          {!!formData?.isAdmin?.length && (
            <InputField
              label="Approval Code for Topups Refund"
              name="approval_code_for_topup_refund"
              value={formData?.approval_code_for_topup_refund}
              onChange={handleInputChange}
              error={formErrors?.approval_code_for_topup_refund}
            />
          )}
        </Box>
      </>
    );
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box
        component={Paper}
        elevation={6}
        sx={{
          p: 2,
        }}
      >
        <Box
          component="form"
          autoCapitalize="off"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h5">
            {userID ? "Update" : "Create"} User Device
          </Typography>
          {renderFormInputs()}
          <Divider
            sx={{
              mb: 2,
              mt: 3,
            }}
          />
          <Typography variant="h6">Device User Permission</Typography>
          {loadingPermissions ? "...Loading permission" : renderCheckBoxes()}
          {conditionalInputBox()}

          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              sx={{
                color: "white",
              }}
              type="submit"
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => navigate(_path)}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

const MyCheckBox = ({
  item,
  values,
  callBack,
  isDisable = false,
  callVendor,
  formData,
  setFormData,
}) => {
  // React.useEffect(() => {
  //   if (item.title.toLowerCase() === "vendor user" && values.indexOf(item?.device_permission_id) >= 0) {
  //     callVendor(true);
  //   }
  // }, []);

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={
              values.indexOf(item?.device_permission_id) >= 0 ? true : false
            }
            onChange={(e) => {
              const isVendorUserOrManager =
                item.title.toLowerCase() === "vendor user" ||
                item.title.toLowerCase() === "vendor manager";

              const isAdmin =
                item.title.toLowerCase() === "settings admin" ||
                item.title.toLowerCase() === "super admin";

              if (
                isAdmin &&
                !formData?.isAdmin?.includes(item?.device_permission_id)
              ) {
                setFormData((prev) => ({
                  ...prev,
                  isAdmin: [...prev.isAdmin, item?.device_permission_id],
                }));
              } else if (
                isAdmin &&
                formData?.isAdmin?.includes(item?.device_permission_id)
              ) {
                setFormData((prev) => ({
                  ...prev,
                  isAdmin: prev.isAdmin.filter(
                    (each) => each !== item?.device_permission_id,
                  ),
                }));
              }

              if (isVendorUserOrManager) {
                const idToAddOrRemove = item?.device_permission_id;
                const isIdInArray =
                  formData?.isVendorNeed.includes(idToAddOrRemove);

                const updatedIsVendorNeed = isIdInArray
                  ? formData.isVendorNeed.filter((id) => id !== idToAddOrRemove)
                  : [...formData.isVendorNeed, idToAddOrRemove];

                const updatedVendor = updatedIsVendorNeed.length > 0;

                setFormData((prev) => ({
                  ...prev,
                  isVendorNeed: updatedIsVendorNeed,
                  vendor: updatedVendor,
                }));
              }
              let _id = item?.device_permission_id;
              let _i = values.indexOf(_id);
              if (_i >= 0) {
                let _newArr = values;
                _newArr.splice(_i, 1);
                callBack([..._newArr]);
              } else {
                callBack((prev) => [...prev, _id]);
              }
            }}
            disabled={isDisable}
          />
        }
        label={item?.title}
      />
    </Box>
  );
};

export default CreateUser;

function toggleAndReturn(arr, item) {
  const index = arr.indexOf(item);

  if (index !== -1) {
    // Item exists in the array, so we remove it and return it
    arr.splice(index, 1);
    return item;
  } else {
    // Item doesn't exist in the array, so we return null
    return false;
  }
}
