import EventIcon from "@mui/icons-material/Event";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import HomeIcon from "@mui/icons-material/Home";
import CategoryIcon from "@mui/icons-material/Category";
import Groups2Icon from "@mui/icons-material/Groups2";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import FestivalIcon from "@mui/icons-material/Festival";
import EmailIcon from "@mui/icons-material/Email";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import SettingsIcon from "@mui/icons-material/Settings";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import AddCardIcon from '@mui/icons-material/AddCard';

export const adminRoutes = [
  {
    label: "Dashboard",
    url: "/",
    routeName: "/",
    icon: <HomeIcon />,
    children: [],
  },
  {
    label: "Organizer",
    url: "/admin/organizer",
    routeName: "/admin/organizer",
    icon: <Groups2Icon />,
    children: [],
  },
  {
    label: "Tickets Orders",
    icon: <ConfirmationNumberIcon />,
    url: "/admin/tickets/tickets-orders",
    routeName: "/admin/tickets/tickets-orders",
    children: [],
  },
  {
    label: "Payout and Taxes",
    url: "/admin/payout-and-taxes",
    routeName: "/admin/payout-and-taxes",
    icon: <AddCardIcon />,
    children: [],
  },
  {
    label: "Platform Fee and Event Payout Tax",
    url: "/admin/fees",
    routeName: "/admin/fees",
    icon: <SettingsIcon />,
    children: [],
  },
  {
    label: "Event Properties",
    icon: <EventIcon />,
    children: [
      {
        label: "Event Categories",
        url: `/admin/event-categories`,
        routeName: `/admin/event-categories`,
      },
      {
        label: "Event Types",
        url: `/admin/event-types`,
        routeName: `/admin/event-types`,
      },
      {
        label: "Home Page Banner",
        url: `/admin/home-page-banners`,
        routeName: `/admin/home-page-banners`,
      },
      {
        label: "Email Illustrations",
        url: `/admin/email-illustrations`,
        routeName: `/admin/email-illustrations`,
      },
      {
        label: "Awaiting for publish",
        url: `/admin/event-properties/awaiting-for-publish`,
        routeName: `/admin/event-properties/awaiting-for-publish`,
      },
      {
        label: "Published events",
        url: `/admin/event-properties/published-events`,
        routeName: `/admin/event-properties/published-events`,
      },
      {
        label: "Faqs",
        url: `/admin/event-properties/faqs`,
        routeName: `/admin/event-properties/faqs`,
      },
    ],
  },
  {
    label: "Payment Methods",
    icon: <ConfirmationNumberIcon />,
    url: "/admin/payment-methods",
    routeName: "/admin/payment-methods",
    children: [],
  },
  {
    label: "Merchandise",
    url: "/admin/merchandise",
    routeName: "/admin/merchandise",
    icon: <CategoryIcon />,
    children: [],
  },
  {
    label: "Currency",
    url: "/admin/currency",
    routeName: "/admin/currency",
    icon: <LocalAtmIcon />,
    children: [],
  },
  {
    label: "Venue",
    url: "/admin/venue",
    routeName: "/admin/venue",
    icon: <FestivalIcon />,
    children: [],
  },
  {
    label: "Contact Queries",
    url: "/admin/contact",
    routeName: "/admin/contact",
    icon: <EmailIcon />,
    children: [],
  },
  {
    label: "Blogs",
    url: "/admin/blogs",
    routeName: "/admin/blogs",
    icon: <DynamicFeedIcon />,
    children: [],
  },
  {
    label: "Demo Requests",
    url: "/admin/demo-requests",
    routeName: "/admin/demo-requests",
    icon: <AvTimerIcon />,
    children: [],
  },
  {
    label: "Download Presentation Requests",
    url: "/admin/download-presentation-requests",
    routeName: "/admin/download-presentation-requests",
    icon: <SlideshowIcon />,
    children: [],
  },
];

export const organizerRoutes = [
  // {
  //   label: "Dashboard",
  //   url: "/",
  //   routeName: "/",
  //   icon: <HomeIcon />,
  //   children: [],
  // },
  {
    label: "Events",
    url: "/events",
    routeName: "/events",
    icon: <EventIcon />,
    children: [],
  },
  // {
  //   label: "User Management",
  //   url: "/user-management",
  //   routeName: "/admin/organizations",
  //   icon: <ManageAccountsIcon />,
  //   children: [],
  // },

  // {
  //   label: "Customers",
  //   url: "/customers",
  //   routeName: "/customers",
  //   icon: <Groups3Icon />,
  //   children: [],
  // },
  {
    label: "About",
    url: "/about",
    routeName: "/",
    icon: <ElectricalServicesIcon />,
    children: [],
  },
];
