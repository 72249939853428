import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  TableCell,
  IconButton,
  Tooltip,
  Button,
  FormControlLabel,
  Switch,
  Modal,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";

import {
  StyledTableCell,
  StyledTableRow,
  TableWrapper,
  TablePagination,
  ConfirmationModal,
  ExportBtn,
} from "../../../component";
import { handleLoader, setToast } from "../../../store/reducer";
import ApiManager from "../../../services/api-manager";
import usePageTitle from "../../../hooks/use-page-title";
import { Visibility } from "@mui/icons-material";

const SpAwaitingForPublish = () => {
  usePageTitle("Awaiting For Publish");
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showImage, setShowImage] = useState(false);
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `superAdmin/publish-requested-events?per_page=${rowsPerPage}&page=${page}`,
      );
      setRecords(data?.response?.detail);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const thLabels = ["Organizer name", "Event name", "Location type", "Action"];

  const handleDelete = async (id) => {
    setIsLoading(true);

    try {
      let { data } = await ApiManager(
        "delete",
        `superAdmin/home-page-banners/${id}`,
      );
      getData();
      showMessage("success", data?.response);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
        Awaiting For Publish
      </Typography>

      <TableWrapper
        spanTd={thLabels.length}
        isLoading={isLoading}
        isContent={records?.data?.length || false}
        thContent={
          <>
            {thLabels.map((v) => (
              <TableCell key={v}>{v}</TableCell>
            ))}
          </>
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        perPage={records?.per_page || 0}
        total={records?.total || 0}
        showPagination
      >
        {records?.data?.length &&
          records?.data?.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.organizer_name}</StyledTableCell>
              <StyledTableCell>{item?.name}</StyledTableCell>
              <StyledTableCell>
                {item?.location_type === "to_be_announced"
                  ? "To be announced"
                  : item?.location_type}
              </StyledTableCell>

              <StyledTableCell>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Tooltip title="View" placement="top">
                    <IconButton
                      color="info"
                      onClick={() =>
                        navigate(
                          `/admin/event-properties/awaiting-for-publish/details/${item?.event_id}`,
                        )
                      }
                    >
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
      </TableWrapper>
    </Box>
  );
};

export default SpAwaitingForPublish;
