import * as React from "react";
import {
  Collapse,
  Box,
  Drawer,
  Toolbar,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  Button,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import { handleLoader, setToast, toggleMenu } from "../../store/reducer";
import ApiManager from "../../services/api-manager";
import { logoutUser } from "../../store/reducer";

const drawerWidth = 260;

export default function ClippedDrawer({ routes, flag }) {
  const { openMenu, user } = useSelector((state) => state.storeReducer);
  const isAdmin = user?.role === "super_admin" ? true : false;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:900px)");

  React.useEffect(() => {
    if (openMenu) {
      dispatch(toggleMenu(false));
    }
  }, [navigate]);

  const logOut = async () => {
    try {
      dispatch(handleLoader(true));
      await ApiManager("post", "logout");
      dispatch(logoutUser());
    } catch (error) {
      dispatch(setToast({ type: "error", message: "Error occurred!" }));
    } finally {
      dispatch(handleLoader(false));
    }
  };

  return (
    <>
      <Drawer
        variant={matches ? "permanent" : "temporary"}
        open={openMenu}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        onClose={() => dispatch(toggleMenu(!openMenu))}
      >
        <Toolbar />
        <Box my={1}>
          <List
            sx={{
              "& .MuiListItemIcon-root": {
                color: "#808080",
              },
            }}
          >
            {routes.map((v, i) => (
              <React.Fragment key={i}>
                {v.children.length ? (
                  <CollapseMenuItem
                    title={v.label}
                    item={v.children}
                    icon={v.icon}
                  ></CollapseMenuItem>
                ) : (
                  <NavItem key={i} item={v} />
                )}
              </React.Fragment>
            ))}
            <Divider sx={{ mt: 3, mb: 3 }} />
            {flag ? (
              <NavButton
                text="Logout"
                icon={<LogoutIcon />}
                handleClick={logOut}
              />
            ) : (
              <NavButton
                text="Return"
                icon={<ArrowLeftIcon />}
                handleClick={() =>
                  navigate(isAdmin ? "/admin/organizer" : "/events")
                }
              />
            )}
          </List>
        </Box>
      </Drawer>
    </>
  );
}

const CollapseMenuItem = ({ title, item, icon }) => {
  const [open, setOpen] = React.useState();

  return (
    <>
      <ListItem disablePadding>
        <NavLink onClick={() => setOpen(!open)} className="admin-drawer">
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>
            <Typography variant="subtitle">{title}</Typography>
          </ListItemText>
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </NavLink>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          sx={{
            backgroundColor: "#6ec4ff14",
          }}
        >
          {item.map((_item, _i) => (
            <List component="div" key={_i} disablePadding>
              <Divider />
              <ListItem disablePadding>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "isActive admin-drawer" : "admin-drawer"
                  }
                  to={_item.url}
                >
                  <ListItemIcon>{_item.icon}</ListItemIcon>
                  <ListItemText>
                    <Typography>{_item.label}</Typography>
                  </ListItemText>
                </NavLink>
              </ListItem>
            </List>
          ))}
        </Box>
      </Collapse>
    </>
  );
};

const NavItem = ({ item }) => {
  return (
    <ListItem
      disablePadding
      sx={{
        width: "100%",
      }}
    >
      <NavLink
        to={item.url}
        className={({ isActive }) =>
          isActive ? "isActive admin-drawer" : "admin-drawer"
        }
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText>
          <Typography variant="subtitle">{item.label}</Typography>
        </ListItemText>
      </NavLink>
    </ListItem>
  );
};

const NavButton = ({ text, icon, handleClick = () => {} }) => {
  return (
    <ListItem
      disablePadding
      sx={{
        width: "100%",
      }}
    >
      <Box
        component="button"
        className="admin-drawer"
        sx={{ border: 0, bgcolor: "transparent", textAlign: "start" }}
        onClick={handleClick}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>
          <Typography variant="subtitle1">{text}</Typography>
        </ListItemText>
      </Box>
    </ListItem>
  );
};
