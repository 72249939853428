import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  TableCell,
  IconButton,
  Tooltip,
  Button,
  FormControlLabel,
  Switch,
  Modal,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";

import {
  StyledTableCell,
  StyledTableRow,
  TableWrapper,
  TablePagination,
  ConfirmationModal,
  ExportBtn,
} from "../../../component";
import { handleLoader, setToast } from "../../../store/reducer";
import ApiManager from "../../../services/api-manager";
import usePageTitle from "../../../hooks/use-page-title";

const SpHomePageBanner = () => {
  usePageTitle("Event Categories");
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showImage, setShowImage] = useState(false);
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager("get", "superAdmin/home-page-banners");
      setRecords(data?.response?.detail);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // const handleStatus = async (e, item) => {
  //     setRecords((prev) =>
  //         prev.map((obj) => {
  //             if (obj.category_id === item.category_id) {
  //                 return { ...obj, active: e.target.checked };
  //             }
  //             return obj;
  //         })
  //     );
  //     let _fd = {
  //         _method: "patch",
  //         status: e.target.checked ? "active" : "not-active",
  //     };
  //     try {
  //         dispatch(handleLoader(true));
  //         await ApiManager(
  //             "post",
  //             `superAdmin/event-categories/${item?.category_id}`,
  //             _fd
  //         );
  //         showMessage("success", "Record status updated successfully.");
  //     } catch (error) {
  //         showMessage("error", error?.response?.data?.error?.message);
  //     } finally {
  //     }
  // };

  const thLabels = ["Event", "Title", "Banner Image", "Banner Text"];

  const handleDelete = async (id) => {
    setIsLoading(true);

    try {
      let { data } = await ApiManager(
        "delete",
        `superAdmin/home-page-banners/${id}`,
      );
      getData();
      showMessage("success", data?.response);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
        Home Page Banners
      </Typography>
      <Stack direction="row" my={2}>
        <Button
          component={Link}
          to="/admin/home-page-banners/add-home-banners"
          startIcon={<AddIcon />}
        >
          Add New Banner
        </Button>
      </Stack>
      <TableWrapper
        spanTd={thLabels.length}
        isLoading={isLoading}
        isContent={records?.length || false}
        thContent={
          <>
            {thLabels.map((v) => (
              <TableCell key={v}>{v}</TableCell>
            ))}
          </>
        }
      >
        {records?.length &&
          records?.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.event_id}</StyledTableCell>
              <StyledTableCell>{item?.text}</StyledTableCell>
              <StyledTableCell>
                <img
                  src={item?.banner_image_url}
                  width="100%"
                  height="50px"
                  alt="banner.png"
                  onClick={() => setShowImage(true)}
                />
              </StyledTableCell>
              {/* <StyledTableCell>
                                <img
                                    src={item?.icon_url}
                                    width="45px"
                                    height="45px"
                                    alt="inactive-icon.png"
                                />
                            </StyledTableCell> */}
              {/* <StyledTableCell>
                                <img
                                    src={item?.icon_active_url}
                                    width="45px"
                                    height="45px"
                                    alt="active-icon.png"
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <img
                                    src={item?.banner_image_url}
                                    width="45px"
                                    height="45px"
                                    alt="banner.png"
                                />
                            </StyledTableCell> */}

              <StyledTableCell>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  {/* <Tooltip title="Edit" placement="top">
                                        <IconButton
                                            color="info"
                                            onClick={() =>
                                                navigate(
                                                    `/admin/home-page-banners/update-home-banners/${item?.banner?.banner_id}`,
                                                )
                                            }
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip> */}
                  <Tooltip title="Delete" placement="top">
                    <IconButton
                      color="info"
                      onClick={() => handleDelete(item?.banner_id)}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
      </TableWrapper>

      {/* <Modal open={showImage} onClose={() => setShowImage(false)}>
                                <Box sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 400,
                                    bgcolor: 'background.paper',
                                    boxShadow: 24,
                                    p: 4,
                                }} >
                                    <img src={e?.banner_image_url} alt="" />
                                </Box>
                            </Modal > */}
    </Box>
  );
};

export default SpHomePageBanner;
