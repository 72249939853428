import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Divider,
    FormControlLabel,
    Grid,
    InputAdornment,
    Paper,
    Stack,
    Switch,
    Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FilePicker, InputField } from "../../../component";
import usePageTitle from "../../../hooks/use-page-title";
import { handleLoader, setToast } from "../../../store/reducer";
import errorsSetter from "../../../helpers/error-setter";
import ApiManager from "../../../services/api-manager";

function SpCreateFaqs() {
    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const showMessage = (type, msg) =>
        dispatch(setToast({ type: type, message: msg }));
    const { id } = useParams();
    usePageTitle(id ? "Update Faqs" : "Add Faqs");

    useEffect(() => {
        async function init() {
            if (id) {
                try {
                    dispatch(handleLoader(true));
                    let { data } = await ApiManager(
                        "get",
                        `superAdmin/admin-faqs/${id}`
                    );
                    setFormData(data?.response?.detail)
                } catch (error) {
                    showMessage("error", error?.response?.data?.error?.message);
                } finally {
                    dispatch(handleLoader(false));
                }
            }
        }
        init();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // const validate = () => {
    //     let errors = {};
    //     let flag = true;
    //     if (!!!formData?.image && !id) {
    //         errors.image = "File required.";
    //         flag = false;
    //     }

    //     if (formData?.price > 100000) {
    //         errors.price = "Price must be below 100k";
    //         flag = false;
    //     }

    //     setFormErrors((prev) => ({ ...prev, ...errors }));
    //     return flag;
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch(handleLoader(true));
            // setFormErrors({});
            let url = id
                ? `superAdmin/admin-faqs/${id}`
                : "superAdmin/admin-faqs";
            let { data } = await ApiManager("post", url, { ...formData, ...(id && { _method: "patch" }) });
            navigate(-1);
            showMessage("success", data?.response);
        } catch (error) {
            if (error?.response?.status === 422) {
                setFormErrors(errorsSetter(error));
            } else {
                showMessage("error", error?.response?.data?.error?.message);
            }
        } finally {
            dispatch(handleLoader(false));
        }
    };

    return (
        <Box
            component={Paper}
            elevation={6}
            sx={{
                p: 2,
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {id ? "Update" : "Add"} Faqs
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        component="form"
                        autoComplete="off"
                        autoCapitalize="off"
                        onSubmit={handleSubmit}
                    >
                        <Grid container spacing={2}>
                            <Grid item md={12 / 3} sm={4} xs={12} lg={3}>
                                <InputField
                                    fullWidth
                                    label="Question"
                                    name="question"
                                    required
                                    error={formErrors?.question}
                                    value={formData?.question}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item md={12 / 3} sm={4} xs={12} lg={3}>
                                <InputField
                                    fullWidth
                                    label="Answer"
                                    name="answer"
                                    required
                                    error={formErrors?.answer}
                                    value={formData?.answer}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item md={12 / 3} sm={4} xs={12} lg={3}>
                                <InputField
                                    fullWidth
                                    label="Order Number"
                                    name="order_number"
                                    required
                                    type="number"
                                    error={formErrors?.order_number}
                                    value={formData?.order_number}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                        </Grid>
                        <Box my={2} children={<Divider />} />

                        <Stack direction="row" gap={2} mt={2}>
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{
                                    color: "white",
                                }}
                            >
                                Save
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => navigate(-1)}
                            >
                                Cancel
                            </Button>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default SpCreateFaqs;
