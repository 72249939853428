import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import { FilePicker, ModalWrapper } from "../../../component";
import ApiManager from "../../../services/api-manager";
import { useDispatch } from "react-redux";
import { handleLoader, setToast } from "../../../store/reducer";

const SpEmailIllustrations = () => {
  const [open, setOpen] = useState(null);
  const [formData, setFormData] = useState({});
  const [getIllustrations, setGetIllustrations] = useState(null);
  const dispatch = useDispatch();

  const getData = async () => {
    try {
      dispatch(handleLoader(true));
      let { data } = await ApiManager("get", "superAdmin/email-illustration");
      setGetIllustrations(data?.response?.detail);
    } catch (error) {
      console.log("🚀 ~ getData ~ error:", error);
    } finally {
      dispatch(handleLoader(false));
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = async (id) => {
    try {
      dispatch(handleLoader(true));
      const { data } = await ApiManager(
        "post",
        "superAdmin/email-illustration",
        formData,
        {
          "Content-Type": "multipart/form-data",
        },
      );
      getIllustrations?.push(data);
      dispatch(setToast({ type: "success", message: "Illustration Added" }));
      setOpen(false);
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error);
    } finally {
      dispatch(handleLoader(false));
      setFormData({});
    }
  };

  const handleDelete = async (id) => {
    try {
      dispatch(handleLoader(true));
      const { data } = await ApiManager(
        "delete",
        `superAdmin/email-illustration/${id}`,
      );
      getData();
      dispatch(setToast({ type: "success", message: data?.response }));
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({});
  };

  const IllustrationCard = ({ data }) => {
    return (
      <>
        <Paper
          sx={{
            minHeight: 200,
            background: `url(${data?.image_url})`,
            backgroundSize: "100%",
            backgroundPosition: "center",
            position: "relative",
          }}
        >
          <Stack
            sx={{
              position: "absolute",
              right: "10px",
              top: "10px",
            }}
            gap={1}
            direction="row"
          >
            <IconButton
              sx={{ color: "white", bgcolor: "primary.main" }}
              onClick={() => handleDelete(data?.email_illustration_id)}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Paper>
      </>
    );
  };

  return (
    <>
      <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
        Event Categories
      </Typography>
      <Button
        sx={{ my: 2 }}
        onClick={() => setOpen(true)}
        startIcon={<AddIcon />}
      >
        Add Email Illustrations
      </Button>

      <Grid container spacing={3}>
        {!getIllustrations?.length ? (
          <Typography textAlign="center" width="max-content" mx="auto" my={5}>
            No Illustration Found
          </Typography>
        ) : (
          getIllustrations?.map((e, i) => {
            return (
              <Grid item key={i} lg={12 / 4} md={12 / 2} xs={12}>
                <IllustrationCard data={e} />
              </Grid>
            );
          })
        )}
      </Grid>

      <ModalWrapper open={open} handleClose={handleClose}>
        <Box width={"100%"} component="form">
          <Typography variant="h5" sx={{ my: 1 }}>
            {" "}
            Add Email Illustrations
          </Typography>
          <FilePicker
            labelTop="Upload Illustrations"
            name="image"
            _id="image"
            onChange={(e) => {
              const file = e.target.files[0];
              setFormData((prev) => ({
                ...prev,
                image: file,
                banner_image_url: file && URL?.createObjectURL(file),
              }));
            }}
            imageUrl={formData?.banner_image_url}
            boxHeight="100%"
            boxMinHeight={{ sm: "400px", xs: "300px" }}
            boxMaxHeight="400px"
            boxWidth="500px"
          // helperText="Recommended size 1920/1080px"
          />
        </Box>
        <Button
          onClick={handleSubmit}
          sx={{ my: 2 }}
          fullWidth
          variant="contained"
        >
          Add
        </Button>
      </ModalWrapper>
    </>
  );
};

export default SpEmailIllustrations;
