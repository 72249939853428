import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  TableCell,
  IconButton,
  Tooltip,
  Button,
  Chip,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import {
  StyledTableCell,
  StyledTableRow,
  TableWrapper,
  TablePagination,
  ConfirmationModal,
  ExportBtn,
} from "../../../../component";
import { handleLoader, setToast } from "../../../../store/reducer";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import { Visibility } from "@mui/icons-material";

const SpOrderDetails = () => {
  usePageTitle("Blogs");
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [modalState, setModalState] = useState(false);
  const [recordID, setRecordID] = useState(null);

  // const [unRead, setUnRead] = useState(0);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `superAdmin/get-ticket-orders?page=${page}&per_page=${rowsPerPage}`,
      );
      console.log("🚀 ~ file: sp-blogs.js:31 ~ getData ~ data:", data);
      setRecords(data);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [rowsPerPage, page]);

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openModal = (_id) => {
    setModalState(true);
    setRecordID(_id);
  };

  const closeModal = () => {
    setModalState(false);
    setRecordID(null);
  };

  const deleteRecord = async () => {
    try {
      dispatch(handleLoader(true));
      await ApiManager("delete", `superAdmin/blogs/${recordID}`);
      showMessage("success", "Blog Deleted successfully.");
      closeModal();
      setRecords((prev) => ({
        ...prev,
        data: prev?.data.filter((item) => item?.blog_id !== recordID),
      }));
    } catch (error) {
      showMessage("error", error?.response?.data?.error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const thLabels = [
    "Name",
    "Starting amount",
    "Payment processing fees",
    "Service fees",
    "Final amount",
    "Status",
    "Actions",
  ];

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Tickets orders
        </Typography>
        {/* <Stack direction="row" my={2}>
          <Button component={Link} to="/admin/blogs/create-blog">
            Create Blog
          </Button>
        </Stack> */}
        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.data?.length &&
            records?.data.map((item, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell>
                  {item?.first_name + "" + item?.last_name}
                </StyledTableCell>
                <StyledTableCell>
                  {item?.starting_amount}{" "}
                  {item?.event_detail.currency?.currency_symbol}
                </StyledTableCell>
                <StyledTableCell>
                  {item?.pp_fees_amount}{" "}
                  {item?.event_detail.currency?.currency_symbol}
                </StyledTableCell>
                <StyledTableCell>
                  {item?.service_fees_amount}{" "}
                  {item?.event_detail.currency?.currency_symbol}
                </StyledTableCell>
                <StyledTableCell>
                  {item?.final_amount}{" "}
                  {item?.event_detail.currency?.currency_symbol}
                </StyledTableCell>
                <StyledTableCell>
                  <Chip
                    label={item?.status}
                    color={
                      item?.status === "completed"
                        ? "success"
                        : item?.status === "pending"
                        ? "warning"
                        : "error"
                    }
                    sx={{ textTransform: "capitalize" }}
                  />
                </StyledTableCell>

                <StyledTableCell>
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="Details" placement="top">
                      <IconButton
                        color="info"
                        onClick={() =>
                          navigate(
                            `/admin/tickets/tickets-orders/details/${item?.ticket_order_id}`,
                          )
                        }
                      >
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableWrapper>
      </Box>
      <ConfirmationModal
        callBack={deleteRecord}
        open={modalState}
        handleClose={closeModal}
      />
    </div>
  );
};

export default SpOrderDetails;
