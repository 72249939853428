import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  Divider,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { FilePicker, InputField, SelectBox } from "../../../../component";
import { handleLoader, setToast } from "../../../../store/reducer";
import errorsSetter from "../../../../helpers/error-setter";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";

const SpCreateEventCategory = () => {
  const eventCatId = useParams().id;
  usePageTitle(eventCatId ? "Update" : "Create" + "Event Category");
  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goBack = () => navigate("/admin/event-categories");
  const [parentCategories, setParentCategories] = useState({});
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    async function init() {
      if (eventCatId) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            `superAdmin/event-categories/${eventCatId}`,
          );
          let res = data?.response?.detail;
          setFormData({
            title: res?.title,
            description: res?.description,
            banner_image_url: res?.banner_image_url,
            status: res?.active,
            parent_id: res?.parent_id || null,
            icon_class: res?.icon_class || ""
          });
        } catch (error) {
          console.log("🚀 ~ file: create-blog.js:42 ~ init ~ error:", error);
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      }
    }
    init();
  }, [eventCatId]);

  const getParentCategories = async () => {
    try {
      dispatch(handleLoader(true));
      let { data } = await ApiManager(
        "get",
        "superAdmin/get-parent-categories",
      );
      const categories = data?.response?.detail?.map((item) => ({
        label: item.title,
        value: item.category_id,
      }));
      setParentCategories([...categories, { label: "None", value: null }]);
    } catch (error) {
      console.log("🚀 ~ getParentCategories ~ error:", error);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  useEffect(() => {
    getParentCategories();
  }, []);

  console.log(formData);

  const validate = () => {
    let errors = {};
    let flag = true;
    if (!eventCatId && !formData?.banner_image) {
      errors.banner_image = "Banner image is required.";
      flag = false;
    }
    // if (!eventCatId && !formData?.icon) {
    //   errors.icon = "Inactive Icon is required.";
    //   flag = false;
    // }
    // if (!eventCatId && !formData?.icon_active) {
    //   errors.icon_active = "Active Icon is required.";
    //   flag = false;
    // }
    if (!formData?.icon_class.trim()) {
      errors.icon_class = "icon class is required";
      flag = false;
    }
    if (!formData?.title) {
      errors.title = "Title is required";
      flag = false;
    }
    if (!formData?.description) {
      errors.description = "Description is required";
      flag = false;
    }
    setFormErrors((prev) => ({ ...prev, ...errors }));
    return flag;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});
    if (!validate()) {
      return;
    }
    try {
      dispatch(handleLoader(true));
      let _url = eventCatId
        ? `superAdmin/event-categories/${eventCatId}`
        : "superAdmin/event-categories";
      let _fd = new FormData();
      for (const key in formData) {
        if (Object.hasOwnProperty.call(formData, key)) {
          if (key === "status") {
            _fd.append(key, formData[key] ? "active" : "not-active");
          } else {
            _fd.append(key, formData[key]);
          }
        }
      }

      if (eventCatId) {
        _fd.append("_method", "patch");
      }
      let { data } = await ApiManager("post", _url, _fd, {
        "Content-Type": "multipart/form-data",
      });
      showMessage(
        "success",
        `Blog ${!!eventCatId ? "updated" : "added"} successfully.`,
      );
      goBack();
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const formInputs = () => {
    return (
      <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
        <Grid item sm={12 / 2} xs={12}>
          <InputField
            label="Title"
            name="title"
            required
            error={formErrors?.title}
            value={formData?.title}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item sm={12 / 2} xs={12}>
          <InputField
            label="Description"
            name="description"
            required
            error={formErrors?.description}
            value={formData?.description}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item sm={12 / 2} xs={12}>
          <SelectBox
            fullWidth
            items={parentCategories.length ? parentCategories : []}
            label="Parent Category"
            name="parent_id"
            error={formErrors?.parent_id}
            value={formData?.parent_id}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item sm={12 / 2} xs={12}>
          <InputField
            label="Icon class"
            name="icon_class"
            required
            error={formErrors?.icon_class}
            value={formData?.icon_class}
            onChange={handleInputChange}
            helperText="Please pick up the font class from the above button link and just put it in the field. For Example: fa-users (which will show users icons)."
          />
        </Grid>
        {eventCatId && (
          <Grid item sm={4} xs={12}>
            <FormControlLabel
              control={<Switch name="status" />}
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  status: e.target.checked,
                }));
              }}
              checked={Boolean(formData?.status)}
              label="Status"
            />
          </Grid>
        )}
        {/* <Grid item>
          <Stack direction={{ xs: "column", sm: "row" }} gap={3}>
            <Box>
              <FilePicker
                labelTop="Inactive Icon"
                name="icon"
                _id="icon"
                onChange={(e) => {
                  const file = e.target.files[0];
                  setFormData((prev) => ({
                    ...prev,
                    icon: file,
                    icon_url: file && URL?.createObjectURL(file),
                  }));
                }}
                error={formErrors?.icon}
                imageUrl={formData?.icon_url}
                boxWidth="210px"
                boxHeight="160px"
                helperText="Recommended size 210/160px"
              />
            </Box>
            <Box>
              <FilePicker
                labelTop="Active Icon"
                name="icon_active"
                _id="icon_active"
                onChange={(e) => {
                  const file = e.target.files[0];
                  setFormData((prev) => ({
                    ...prev,
                    icon_active: file,
                    icon_active_url: file && URL?.createObjectURL(file),
                  }));
                }}
                error={formErrors?.icon_active}
                imageUrl={formData?.icon_active_url}
                boxWidth="210px"
                boxHeight="160px"
                helperText="Recommended size 210/160px"
              />
            </Box>
          </Stack>
        </Grid> */}
        <Grid item xs={12}>
          <Box width={"100%"}>
            <FilePicker
              labelTop="Banner image"
              name="banner_image"
              _id="banner_image"
              onChange={(e) => {
                const file = e.target.files[0];
                setFormData((prev) => ({
                  ...prev,
                  banner_image: file,
                  banner_image_url: file && URL?.createObjectURL(file),
                }));
              }}
              error={formErrors?.banner_image}
              imageUrl={formData?.banner_image_url}
              boxWidth="100%"
              boxHeight="100%"
              boxMinHeight={{ sm: "500px", xs: "300px" }}
              boxMaxHeight="500px"
              helperText="Recommended size 1920/1080px"
            />
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box
        component={Paper}
        elevation={6}
        sx={{
          p: 2,
        }}
      >
        <Box
          component="form"
          autoCapitalize="off"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">
              {eventCatId ? "Update" : "Add"} Event Category
            </Typography>
            <Button variant="contained" href="https://fontawesome.com/v4/icons/" target="_blank">Get Icon Class</Button>
          </Stack>
          {formInputs()}

          <Box mt={2}>
            <Divider />
          </Box>
          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              sx={{
                color: "white",
              }}
              type="submit"
            >
              Submit
            </Button>
            <Button variant="contained" color="error" onClick={goBack}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default SpCreateEventCategory;

const initialFormData = {
  parent_id: null,
};
